import { graphql, useStaticQuery } from 'gatsby';

export const useCategoriesQuery = () => {
  const result = useStaticQuery(graphql`
    query categoriesForNavigation {
      allGoogleSpreadsheetCategoryTree(filter: { isPublished: { eq: "TRUE" } }) {
        edges {
          node {
            uuid
            name
            parentCategory
            productCount
            isFeatured
          }
        }
      }
    }
  `);
  return result;
};
