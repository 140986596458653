import cx from 'classnames';
import { Link } from 'gatsby';
import React, { useRef, useState } from 'react';

import SearchSvg from '@/assets/icons/Search.svg';
import { Text } from '@/components/common/Input';
import Popover from '@/components/common/Popover';
import { useBrandsQuery } from '@/query/brands';
import { useCategoriesQuery } from '@/query/categories';
import { useProductsQuery } from '@/query/products';
import { accessibleOnClick } from '@/utils/react';
import { normalizeString } from '@/utils/string';
import { ROUTE_NAMES, stringToSlug } from '@/utils/url';

import * as style from './searchbar.module.scss';

const SearchBar = ({ className, onSearchBarStatusChange }) => {
  const [filteredResults, setFilteredResults] = useState({
    products: [],
    brands: [],
    categories: [],
  });
  const resultsLimit = 5;
  const [hasSearchTerm, setHasSearchTerm] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const showPopover = hasSearchTerm && isFocused;

  const productsQuery = useProductsQuery();
  const allProducts = productsQuery.allGoogleSpreadsheetProducts.edges.map((e) => e.node);
  const brandsQuery = useBrandsQuery();
  const allBrands = brandsQuery.allGoogleSpreadsheetBrands.edges.map((e) => e.node);

  const categoriesQuery = useCategoriesQuery();
  const allCategories = categoriesQuery.allGoogleSpreadsheetCategoryTree.edges.map((e) => e.node);

  const searchField = useRef();
  const hasResults = Object.values(filteredResults).some((e) => !!e.length);

  const handleEmptySearch = () => {
    searchField.current.value = '';
    setHasSearchTerm(false);
  };

  const handleIsFocused = (isFocused) => {
    setIsFocused(isFocused);
    onSearchBarStatusChange(isFocused);
  };

  const handleSearchClick = () => {
    handleIsFocused(true);
    setTimeout(() => {
      searchField.current.focus();
    });
  };

  const handleFilter = () => {
    const searchTerms = normalizeString(searchField.current.value)
      .split(' ')
      .filter((e) => e !== '');
    setHasSearchTerm(!!searchTerms.length);
    const products = allProducts.filter((product) => {
      const title = normalizeString(product.title);
      const shortDescription = normalizeString(product.shortDescription);
      const comparer = `${title} ${shortDescription}`;
      return searchTerms.every((term) => comparer.includes(term));
    });

    const brands = allBrands.filter((brand) =>
      searchTerms.some((term) => normalizeString(brand.name).includes(term)),
    );
    const categories = allCategories.filter((category) =>
      searchTerms.every((term) => normalizeString(category.name).includes(term)),
    );

    setFilteredResults({
      [ROUTE_NAMES.PRODUCTS]: products,
      [ROUTE_NAMES.BRANDS]: brands,
      [ROUTE_NAMES.CATEGORIES]: categories,
    });
  };

  return (
    <div className={cx(style.main, isFocused && style.isOpen, className)}>
      <Popover
        className={style.popover}
        openOnClick={false}
        displayPopover={showPopover}
        renderChildren={() => (
          <div className={style.results}>
            {hasResults ? (
              Object.keys(filteredResults).map((resultType) => {
                const resultsAmount = filteredResults[resultType].length;
                const resultsExceedLimit = resultsAmount > resultsLimit;
                const isProductResult = resultType === ROUTE_NAMES.PRODUCTS;
                return (
                  !!resultsAmount && (
                    <section key={resultType} className={style.resultTypeSection}>
                      <h3 className={style.resultType}>{resultType}</h3>
                      <ul className={style.resultsList}>
                        {filteredResults[resultType].slice(0, resultsLimit).map((result) => (
                          <li key={result.uuid}>
                            <Link
                              className={cx(style.resultLink, isProductResult && style.isProduct)}
                              onClick={() => handleEmptySearch()}
                              to={`/${resultType}/${
                                isProductResult ? stringToSlug(result.title) : result.uuid
                              }`}
                            >
                              <span className={style.title}>
                                {result.title || result.name || result.uuid}
                              </span>
                              <span className={style.description}>
                                {isProductResult ? (
                                  result.shortDescription
                                ) : (
                                  <>
                                    <strong>{result.productCount}</strong>
                                    {` producto${result.productCount === 1 ? '' : 's'}`}
                                  </>
                                )}
                              </span>
                            </Link>
                          </li>
                        ))}
                        {resultsExceedLimit && isProductResult && (
                          <li>
                            <Link
                              className={cx(style.resultLink, style.viewAll)}
                              to={`/search?q=${searchField.current.value}`}
                            >
                              Ver los {resultsAmount} resultados →
                            </Link>
                          </li>
                        )}
                      </ul>
                    </section>
                  )
                );
              })
            ) : (
              <p>{`No se econtraron resultados para "${(searchField.current || {}).value}"`}</p>
            )}
          </div>
        )}
      >
        <Text
          className={style.input}
          autoComplete="off"
          name="search"
          placeholder="Buscar productos, marcas o categorías"
          onFocus={() => handleIsFocused(true)}
          onBlur={() => setTimeout(() => handleIsFocused(false), 250)}
          ref={searchField}
          onChange={() => handleFilter()}
          type="text"
        />
      </Popover>
      <div
        className={style.searchIcon}
        {...accessibleOnClick(() => handleSearchClick())}
        role="button"
        tabIndex={0}
      >
        <SearchSvg />
      </div>
    </div>
  );
};

export default SearchBar;
