import cx from 'classnames';
import React from 'react';

import * as style from './closebutton.module.scss';

const CloseButton = ({ className, isHamburger, isFilter, ...props }) => (
  <span
    className={cx(style.main, className, {
      [style.isHamburger]: isHamburger,
      [style.isFilter]: isFilter,
    })}
    {...props}
    aria-label="Close"
    role="button"
  >
    <span className={style.middleLine} aria-label="middle-line" role="gridcell" />
  </span>
);

export default CloseButton;
