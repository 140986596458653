const qs = require('qs');
const { stringToBoolean, stringToInt } = require('./string');

const stringToSlug = (str) => {
  if (!str) return;
  let slug = str.replace(/^\s+|\s+$/g, ''); // trim
  slug = slug.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaeeeeiiiioooouuuunc------';
  for (var i = 0, l = from.length; i < l; i++) {
    slug = slug.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  slug = slug
    .replace(/[^a-z0-9+ -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return slug;
};

const getImageDirFromURL = (url) => url.split('/')[1];

const ROUTE_NAMES = {
  CATEGORIES: 'categorias',
  PRODUCTS: 'productos',
  BRANDS: 'marcas',
  CONTACT: 'contacto',
  TERMS_CONDITIONS: 'terminos-condiciones',
  SEARCH: 'search',
};

const getUrlQueryParams = (location) => {
  return qs.parse(location.search.replace('?', ''), {
    encode: false,
  });
};

const getProductFiltersFromUrl = (location) => {
  const urlFilters = getUrlQueryParams(location);
  const { brands, priceFrom, priceTo, newArrivals, featured, offers, page, sortBy } = urlFilters;

  const parsedFilters = {
    brands: brands || [],
    priceFrom: stringToInt(priceFrom),
    priceTo: stringToInt(priceTo),
    newArrivals: stringToBoolean(newArrivals),
    featured: stringToBoolean(featured),
    offers: stringToBoolean(offers),
    sortBy,
    page,
  };

  return parsedFilters;
};

module.exports = {
  stringToSlug,
  getImageDirFromURL,
  getUrlQueryParams,
  getProductFiltersFromUrl,
  ROUTE_NAMES,
};
