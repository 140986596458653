import cx from 'classnames';
import React from 'react';

import LogoVisciglio from '@/assets/images/visciglio.svg';

import * as style from './mainlogo.module.scss';

const MainLogo = ({ className }) => <LogoVisciglio className={cx(className, style.main)} />;

export default MainLogo;
