import cx from 'classnames';
import React from 'react';

import * as style from './backbutton.module.scss';

const BackButton = ({ className, ...props }) => (
  <div className={cx(style.main, className)} {...props} aria-label="Back" role="button">
    <span className={style.middleLine} aria-label="middle-line" role="gridcell" />
  </div>
);

export default BackButton;
