import cx from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import * as style from './popover.module.scss';

const Popover = ({
  className,
  openOnClick = true,
  displayPopover = false,
  renderChildren,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selector = useRef();
  const popover = useRef();
  useEffect(() => {
    if (openOnClick) document.addEventListener('mousedown', handleClick);
    return () => {
      if (openOnClick) document.removeEventListener('mousedown', handleClick);
    };
  }, [openOnClick]);

  const close = () => {
    setIsOpen(false);
  };

  const handleClick = (e) => {
    if (selector.current.contains(e.target)) {
      setIsOpen((prev) => !prev);
      return;
    }
    if (popover.current && popover.current.contains(e.target)) {
      return;
    }
    setIsOpen(false);
  };

  return (
    <div className={cx(style.main, className)}>
      <div ref={selector}>{children}</div>
      {(isOpen || displayPopover) && (
        <div className={style.popover} ref={popover} role="listbox">
          {renderChildren(close)}
        </div>
      )}
    </div>
  );
};

export default Popover;
