import React, { useEffect, useState } from 'react';

import Loading from '@/components/common/Loading';
import Footer from '@/components/layout/Footer';
import Header from '@/components/layout/Header';
import ContactTeaser from '@/components/section/Contact/Teaser';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './layout.module.scss';

const { setSitePrices } = require('@/query/site_settings');

const Layout = ({ children, location }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const siteSettings = async () => {
      await setSitePrices();
      setLoading(false);
    };
    siteSettings();
  }, []);

  const showContactTeaser =
    !location.pathname.includes(ROUTE_NAMES.CONTACT) &&
    !location.pathname.includes(ROUTE_NAMES.PRODUCTS);
  return (
    <div className={style.wrapper}>
      <Header location={location} />
      {loading ? <Loading /> : <main className={style.main}>{children}</main>}
      {showContactTeaser && <ContactTeaser />}
      <Footer />
    </div>
  );
};

export default Layout;
