import cx from 'classnames';
import { navigate } from 'gatsby';
import { groupBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';

import BackButton from '@/components/common/BackButton';
import Button from '@/components/common/Button';
import CloseButton from '@/components/common/CloseButton';
import { useCategoriesQuery } from '@/query/categories';
import { categoryUuidFromUrl } from '@/utils/parsing';
import { accessibleOnClick, useDeviceDetect } from '@/utils/react';
import { categoryParentsFromCategory } from '@/utils/traversing';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './navigation.module.scss';

const Navigation = ({ onCloseNavigation, location }) => {
  const [navStep, setNavStep] = useState(1);
  const main = useRef();
  const { isMobile } = useDeviceDetect();
  const data = useCategoriesQuery();
  const categories = data.allGoogleSpreadsheetCategoryTree.edges.map((e) => e.node);
  const ROOT_CATEGORY = 'ROOT_CATEGORY';
  const categoryTree = groupBy(categories, (cat) => cat.parentCategory || ROOT_CATEGORY);
  const categoryUuid = categoryUuidFromUrl(location);
  const hasChildren = categoryTree[categoryUuid];
  const selected = categoryUuid
    ? categoryParentsFromCategory(categoryUuid, categories).map((e) => e.uuid)
    : { 0: null, 1: null, 2: null };
  const levels = [
    categoryTree[ROOT_CATEGORY],
    categoryTree[selected[0]],
    categoryTree[selected[1]],
  ];
  useEffect(() => {
    setNavStep(hasChildren ? selected.length : Math.max((selected.length || 0) - 1, 0));
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClick = ({ categoryUuid, index }) => {
    if (!!selected.length && categoryUuid === selected[selected.length - 1]) {
      setNavStep(navStep + 1);
    }
    navigate(`/${ROUTE_NAMES.CATEGORIES}/${categoryUuid}`);
    const isLeaf = !categoryTree[categoryUuid];
    if (isLeaf) {
      onCloseNavigation();
    }
  };

  const handleNavigateBack = () => {
    setNavStep(navStep - 1);
  };

  return (
    <div className={style.main} id="nav" ref={main}>
      <CloseButton className={style.close} onClick={onCloseNavigation} />
      {!!navStep && (
        <>
          <BackButton className={style.back} onClick={() => handleNavigateBack()} />
        </>
      )}
      <nav
        className={cx(style.navigation, navStep === 0 && style.isFirstPanel)}
        style={{
          transform: isMobile ? `translateX(-${navStep * 100}%)` : 'none',
        }}
      >
        {levels.map(
          (level, index) =>
            level && (
              <ul
                key={index}
                className={cx(style.categoryLevel, navStep === index && style.isVisible)}
              >
                {level.map((category) => (
                  <li key={category.uuid}>
                    <div
                      role="button"
                      className={cx(style.categoryItem, {
                        [style.selected]: selected[index] === category.uuid,
                      })}
                      {...accessibleOnClick(() => {
                        handleClick({
                          categoryUuid: category.uuid,
                          index,
                        });
                      })}
                    >
                      <Button noUnderline={selected[index] !== category.uuid}>
                        {category.name}
                      </Button>
                    </div>
                  </li>
                ))}
              </ul>
            ),
        )}
      </nav>
    </div>
  );
};

export default Navigation;
