import cx from 'classnames';
import React from 'react';

import * as style from './loading.module.scss';

const Loading = ({ className }) => (
  <div className={cx(style.main, className)}>
    <i className={style.item}></i>
    <i className={style.item}></i>
    <i className={style.item}></i>
  </div>
);

export default Loading;
