import { graphql, useStaticQuery } from 'gatsby';

export const useSiteQuery = () =>
  useStaticQuery(
    graphql`
      query site {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
            email
            address {
              text
              link
            }
            social {
              instagram {
                text
                link
              }
              whatsapp {
                text
                link
              }
            }
            phone_numbers {
              landline {
                text
                link
              }
            }
            horario {
              line1
              line2
            }
            gmapsPlaceId
            googleApiKey
          }
        }
      }
    `,
  );
