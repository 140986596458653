import cx from 'classnames';
import { Link } from 'gatsby';
import React from 'react';

import InstagramSvg from '@/assets/icons/Instagram.svg';
import WhatsappSvg from '@/assets/icons/Whatsapp.svg';
import VisciglioEmblem from '@/assets/images/visciglio_emblem.svg';
import { useBrandsQuery } from '@/query/brands';
import { useCategoriesQuery } from '@/query/categories';
import { useSiteQuery } from '@/query/site';
import { treeFromFlatArray } from '@/utils/traversing';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './footer.module.scss';

const Footer = () => {
  const categoriesData = useCategoriesQuery();
  const brandsData = useBrandsQuery();
  const siteData = useSiteQuery();
  const { address, phone_numbers, email, social } = siteData.site.siteMetadata;

  const categories = categoriesData.allGoogleSpreadsheetCategoryTree.edges.map((e) => e.node);
  const featuredCats = treeFromFlatArray(categories);

  const brands = brandsData.allGoogleSpreadsheetBrands.edges.map((e) => e.node);
  const featuredBrands = brands.filter((e) => e.isFeatured === 'TRUE');

  return (
    <footer className={style.main}>
      <div className={style.container}>
        <div>
          <Link to="/" className={style.logo}>
            <VisciglioEmblem />
          </Link>
          <ul className={style.contactData}>
            <li>
              <a href={phone_numbers.landline.link}>{phone_numbers.landline.text}</a>
            </li>
            <li>
              <a href="/contacto">{email}</a>
            </li>
            <li>
              <a href={address.link} target="_blank" rel="noopener noreferrer">
                {address.text}
              </a>
            </li>
            <li>
              <ul className={style.social}>
                <li>
                  <a href={social.instagram.link} target="_blank" rel="noopener noreferrer">
                    <InstagramSvg />
                  </a>
                </li>
                <li>
                  <a href={social.whatsapp.link} target="_blank" rel="noopener noreferrer">
                    <WhatsappSvg />
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <nav className={style.navigation}>
          <ul className={cx(style.navList, style.brands)}>
            <li className={style.title}>
              <h4 className={style.navTitle}>
                <Link to={`/${ROUTE_NAMES.BRANDS}`}>Marcas</Link>
              </h4>
            </li>
            {featuredBrands.map((brand) => (
              <li key={brand.uuid}>
                <Link className={style.navItem} to={`/${ROUTE_NAMES.BRANDS}/${brand.uuid}`}>
                  {brand.name}
                </Link>
              </li>
            ))}
          </ul>
          <div className={style.categories}>
            {Object.values(featuredCats).map((category) => (
              <ul key={category.uuid} className={style.navList}>
                <li>
                  <h4 className={style.navTitle}>
                    <Link to={`/${ROUTE_NAMES.CATEGORIES}/${category.uuid}`}>{category.name}</Link>
                  </h4>
                </li>
                {category.children.map((subcategory) => (
                  <li key={subcategory.uuid}>
                    <Link
                      className={style.navItem}
                      to={`/${ROUTE_NAMES.CATEGORIES}/${subcategory.uuid}`}
                    >
                      {subcategory.name}
                    </Link>
                  </li>
                ))}
              </ul>
            ))}
            <ul className={style.navList}>
              <h4 className={style.navTitle}>La empresa</h4>
              <li>
                <Link to={`/${ROUTE_NAMES.CONTACT}`} className={style.navItem}>
                  Contacto
                </Link>
              </li>
              <li>
                <Link to={`/${ROUTE_NAMES.TERMS_CONDITIONS}`} className={style.navItem}>
                  Términos y Condiciones
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
