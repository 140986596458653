const { get } = require('lodash');
const { getImageDirFromURL } = require('./url');
const { useLocalImages } = require('./env.js');

const treeFromFlatArray = (arr) => {
  let map = {};
  let tree = [];

  arr.forEach((e) => {
    map[e.uuid] = { ...e, children: [] };
  });

  arr.forEach((e) => {
    if (e.parentCategory && map[e.parentCategory]) {
      map[e.parentCategory].children.push(map[e.uuid]);
    } else {
      tree.push(map[e.uuid]);
    }
  });
  return tree;
};

const featuredCategoriesFromFlatArray = (arr) => {
  let rootCategories = {};
  let map = {};

  arr.forEach((e) => {
    rootCategories[e.uuid] = rootCategories[e.parentCategory]
      ? rootCategories[e.parentCategory]
      : e.parentCategory;
  });

  arr.forEach((e) => {
    if (!e.parentCategory) {
      map[e.uuid] = { ...e, children: [] };
    } else if (e.isFeatured === 'TRUE') {
      const parentRootCategory = rootCategories[e.parentCategory] || e.parentCategory;
      map[parentRootCategory].children.push(e);
    }
  });

  return map;
};

const getParentCategory = (category, categories) => {
  return categories.find((e) => e.uuid === category.parentCategory);
};

const getBrandNameFromUuid = (uuid, brands) => {
  const brand = brands.find((e) => e.uuid === uuid);
  return brand && brand.name;
};

const getCategoryNameFromUuid = (uuid, categories) => {
  const category = categories.find((e) => e.uuid === uuid);
  return category && category.name;
};

const categoryParentsFromCategory = (categoryUuid, categories) => {
  let currentCat = categories.find((e) => e.uuid === categoryUuid);
  if (!currentCat) return [];
  const tree = [currentCat];
  while (currentCat.parentCategory) {
    const parentCategory = getParentCategory(currentCat, categories);
    tree.push(parentCategory);
    currentCat = parentCategory;
  }
  return tree.reverse();
};

const getLocalOrRemoteImages = (images) => {
  const imgs = useLocalImages
    ? images.allFile.edges.map((e) => ({
        ...e.node,
        path: e.node.relativePath,
      }))
    : get(images, 'allS3Object.edges', []).map((e) => ({
        ...e.node.localFile,
        path: e.node.Key,
      }));
  return imgs.sort((a, b) => (a.path > b.path ? 1 : -1));
};

const getProductImages = (uuid, images) => {
  return images
    .filter((img) => {
      const uuidFromDir = getImageDirFromURL(img.path);
      return uuidFromDir === uuid.toString();
    })
    .sort((a, b) => (a.path > b.path ? 1 : -1));
};

const mergeProductsAndPrices = (products) => {
  const prices = typeof window !== 'undefined' && window.localStorage.getItem('prices');
  const pricesById = JSON.parse(prices);
  if (!pricesById) return products;
  return {
    allGoogleSpreadsheetProducts: {
      edges: products.allGoogleSpreadsheetProducts.edges.map((e) => ({
        node: {
          ...e.node,
          priceArs: pricesById[e.node.uuid],
        },
      })),
    },
  };
};

module.exports = {
  treeFromFlatArray,
  featuredCategoriesFromFlatArray,
  categoryParentsFromCategory,
  getBrandNameFromUuid,
  getCategoryNameFromUuid,
  getLocalOrRemoteImages,
  getProductImages,
  mergeProductsAndPrices,
};
