import { graphql, useStaticQuery } from 'gatsby';

export const useBrandsQuery = () => {
  return useStaticQuery(graphql`
    query brandsForSelect2 {
      allGoogleSpreadsheetBrands {
        edges {
          node {
            uuid
            name
            isFeatured
            productCount
          }
        }
      }
      allFile(filter: { relativeDirectory: { regex: "/marcas/" } }) {
        edges {
          node {
            relativePath
            extension
            publicURL
          }
        }
      }
    }
  `);
};
