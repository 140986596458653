import { debounce, noop } from 'lodash';
import { useEffect, useState } from 'react';

export const accessibleOnClick = (onClickFn) => ({
  onKeyDown: (ev) => (ev.key === 'Enter' ? onClickFn : noop)(ev),
  onClick: onClickFn,
});

export const useDeviceDetect = () => {
  const [isMobile, setMobile] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
    );
    setMobile(mobile);
  }, [windowDimensions]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', debounce(handleResize, 200));

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile };
};
