const fromPairs = require('lodash/fromPairs');

const RANGES = ['products!A2:A', 'products!G2:G', 'settings!A2:B'];
const RANGE = RANGES.map((e) => `ranges=${e}`).join('&');
const setSitePrices = async () => {
  const response = await fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${process.env.GATSBY_SPREADSHEET_ID}/values:batchGet?${RANGE}&key=${process.env.GATSBY_GOOGLE_API_KEY}`,
  );
  const data = await response.json();
  const [ids, prices, settings] = data.valueRanges.map((e) => e.values);
  const pricesById = {};
  ids.forEach((id, index) => {
    pricesById[id] = +(prices[index] || [])[0];
  });
  typeof window !== 'undefined' &&
    window.localStorage.setItem('prices', JSON.stringify(pricesById));
  window.localStorage.setItem('settings', JSON.stringify(fromPairs(settings)));
};

module.exports = {
  setSitePrices,
};
