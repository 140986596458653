import { graphql, useStaticQuery } from 'gatsby';

import { mergeProductsAndPrices } from '@/utils/traversing';

export const useProductsQuery = () => {
  const query = useStaticQuery(graphql`
    query products {
      allGoogleSpreadsheetProducts(filter: { isPublished: { eq: "TRUE" } }) {
        edges {
          node {
            uuid
            title
            shortDescription
            description
            brand
            isNew
            isFeatured
            isOffer
          }
        }
      }
    }
  `);

  return mergeProductsAndPrices(query);
};
