import cx from 'classnames';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';

import CloseButton from '@/components/common/CloseButton';
import MainLogo from '@/components/common/MainLogo';
import CategoryNavigation from '@/components/section/Category/Navigation';
import SearchBar from '@/components/section/Search/SearchBar';
import { useDeviceDetect } from '@/utils/react';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './header.module.scss';

const NavLink = ({ link, children, ...props }) =>
  link.slug === ROUTE_NAMES.PRODUCTS ? (
    <span {...props}>{children}</span>
  ) : (
    <Link {...props}>{children}</Link>
  );

const Header = ({ location }) => {
  const [categoryMenuIsExpanded, setCategoryMenuIsExpanded] = useState(false);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [menuIsExpanded, setMenuIsExpanded] = useState(false);
  const { isMobile } = useDeviceDetect();

  const handleSearchStatus = (isOpen) => {
    setSearchIsOpen(isOpen);
  };

  useEffect(() => {
    if (!location.pathname.includes(ROUTE_NAMES.CATEGORIES)) {
      setCategoryMenuIsExpanded(false);
    }
  }, [location]);

  const navLinks = [
    {
      slug: ROUTE_NAMES.PRODUCTS,
      title: 'Productos',
      isActive:
        location.pathname.includes(ROUTE_NAMES.PRODUCTS) ||
        location.pathname.includes(ROUTE_NAMES.CATEGORIES),
    },
    {
      slug: ROUTE_NAMES.BRANDS,
      title: 'Marcas',
      isActive: location.pathname.includes(ROUTE_NAMES.BRANDS),
    },
    {
      slug: ROUTE_NAMES.CONTACT,
      title: 'Contacto',
      isActive: location.pathname.includes(ROUTE_NAMES.CONTACT),
    },
  ];

  const handleClick = (slug) => {
    if (slug === ROUTE_NAMES.PRODUCTS) {
      setCategoryMenuIsExpanded(true);
    }
  };
  const handleLinkMouseEnter = (slug) => {
    if (slug === ROUTE_NAMES.PRODUCTS) {
      setCategoryMenuIsExpanded(true);
    }
  };
  const handleLinkMouseLeave = (slug, withDelay) => {
    if (slug === ROUTE_NAMES.PRODUCTS) {
      setTimeout(
        () => {
          setCategoryMenuIsExpanded(false);
        },
        withDelay ? 1000 : 0,
      );
    }
  };

  const onToggleMenu = () => {
    setMenuIsExpanded(!menuIsExpanded);
  };

  return (
    <header className={cx(style.main, menuIsExpanded && style.isExpanded)}>
      <div className={style.mainbar}>
        <div className={style.container}>
          <Link to="/">
            <MainLogo />
          </Link>
          <CloseButton
            className={style.close}
            onClick={onToggleMenu}
            isHamburger={!menuIsExpanded}
          />
          <nav
            className={cx(style.mainNavigation, {
              [style.isExpanded]: menuIsExpanded,
            })}
          >
            <ul className={style.navList}>
              {navLinks.map((link) => (
                <li key={link.title} className={cx(searchIsOpen && style.hidden)}>
                  <NavLink
                    onClick={() => handleClick(link.slug)}
                    onMouseEnter={() => handleLinkMouseEnter(link.slug)}
                    link={link}
                    to={`/${link.slug}`}
                    className={cx(style.navItem, link.isActive && style.isActive)}
                  >
                    {link.title}
                  </NavLink>
                </li>
              ))}
              <li className={style.searchNavItem}>
                <SearchBar onSearchBarStatusChange={handleSearchStatus} />
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div
        className={cx(style.categories, categoryMenuIsExpanded && style.isVisible)}
        onMouseEnter={() => !isMobile && handleLinkMouseEnter(ROUTE_NAMES.PRODUCTS)}
        onMouseLeave={() => !isMobile && handleLinkMouseLeave(ROUTE_NAMES.PRODUCTS, false)}
        role="presentation"
      >
        <div className={style.container}>
          <CategoryNavigation
            location={location}
            onCloseNavigation={() => {
              setCategoryMenuIsExpanded(false);
            }}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
