// extracted by mini-css-extract-plugin
export var description = "searchbar-module--description--93562";
export var input = "searchbar-module--input--4faa2";
export var isOpen = "searchbar-module--isOpen--ac19f";
export var isProduct = "searchbar-module--isProduct--81fa8";
export var main = "searchbar-module--main--9d124";
export var popover = "searchbar-module--popover--5ed72";
export var resultLink = "searchbar-module--resultLink--5e534";
export var resultType = "searchbar-module--resultType--88766";
export var resultTypeSection = "searchbar-module--resultTypeSection--046cd";
export var results = "searchbar-module--results--143da";
export var resultsList = "searchbar-module--resultsList--542e5";
export var searchIcon = "searchbar-module--searchIcon--c5be8";
export var title = "searchbar-module--title--53b33";
export var viewAll = "searchbar-module--viewAll--da30e";