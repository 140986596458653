import cx from 'classnames';
import React, { forwardRef } from 'react';

import * as style from './input.module.scss';

const Text = forwardRef(({ className, ...props }, ref) => (
  <input ref={ref} type="text" {...props} className={cx(style.main, className)} />
));

export default Text;

const withType = (type) =>
  forwardRef(({ ...props }, ref) => <Text {...props} ref={ref} type={type} />);

export const Email = withType('email');
export const Password = withType('password');
export const Phone = withType('tel');
