import { Link } from 'gatsby';
import React from 'react';

import headphones from '@/assets/images/contacto/headphones.png';
import { ROUTE_NAMES } from '@/utils/url';

import * as style from './contactteaser.module.scss';

const Teaser = () => {
  return (
    <section className={style.main}>
      <div className={style.container}>
        <div>
          <h2>Queremos ayudarte a hacer música</h2>
          <Link to={`/${ROUTE_NAMES.CONTACT}`} className={style.cta}>
            Envianos tu consulta →
          </Link>
        </div>
        <div className={style.image}>
          <img src={headphones} alt="Envianos tu consulta" />
        </div>
      </div>
    </section>
  );
};

export default Teaser;
