import { tail } from 'lodash';

import { ROUTE_NAMES } from '@/utils/url';

const arrayFromQueryString = (queryString) => {
  if (!queryString) return [];
  const params = new URLSearchParams(queryString);
  const array = [];
  for (let param of params) {
    const [key, value] = param;
    array.push({ key, value });
  }
  return array;
};

const categoryUuidFromUrl = (windowLocation) => {
  const split = tail(windowLocation.pathname.split(`/${ROUTE_NAMES.CATEGORIES}/`));
  return split && split[0] && split[0].replace('/', '');
};

export { arrayFromQueryString, categoryUuidFromUrl };
