import cx from 'classnames';
import React from 'react';

import { accessibleOnClick } from '@/utils/react';

import * as style from './button.module.scss';

const Button = ({
  isText,
  isPrimary,
  isSecondary,
  isBlock,
  isChip,
  isLoading,
  noUnderline,
  children,
  onClick,
  className,
  ...props
}) => (
  <button
    tabIndex={0}
    {...accessibleOnClick(onClick)}
    className={cx(
      style.main,
      {
        [style.isText]: isText,
        [style.isPrimary]: isPrimary,
        [style.isSecondary]: isSecondary,
        [style.isChip]: isChip,
        [style.isLoading]: isLoading,
        [style.isBlock]: isBlock,
        [style.noUnderline]: noUnderline,
      },
      className,
    )}
    {...props}
  >
    {children}
  </button>
);

export default Button;
