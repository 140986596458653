import cx from 'classnames';
import React from 'react';

import * as style from './textarea.module.scss';

const Textarea = ({ className, ...props }) => (
  <textarea {...props} className={cx(style.main, className)} />
);

export default Textarea;
