// extracted by mini-css-extract-plugin
export var categories = "header-module--categories--fc303";
export var close = "header-module--close--4b4a7";
export var container = "header-module--container--6ce4a";
export var hidden = "header-module--hidden--7f694";
export var isActive = "header-module--isActive--b4f6d";
export var isExpanded = "header-module--isExpanded--7085b";
export var isVisible = "header-module--isVisible--822f3";
export var logo = "header-module--logo--ff0c2";
export var main = "header-module--main--f8ec0";
export var mainNavigation = "header-module--mainNavigation--d1770";
export var mainbar = "header-module--mainbar--b60f9";
export var navItem = "header-module--navItem--5fa9c";
export var navList = "header-module--navList--caf9e";
export var searchNavItem = "header-module--searchNavItem--57e6b";