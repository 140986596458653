// extracted by mini-css-extract-plugin
export var brands = "footer-module--brands--fa20a";
export var categories = "footer-module--categories--25411";
export var contactData = "footer-module--contactData--7ff94";
export var container = "footer-module--container--72d43";
export var logo = "footer-module--logo--d5c59";
export var main = "footer-module--main--9a0ba";
export var navItem = "footer-module--navItem--aa590";
export var navList = "footer-module--navList--840d7";
export var navTitle = "footer-module--navTitle--79ba6";
export var navigation = "footer-module--navigation--a28a7";
export var social = "footer-module--social--41816";
export var title = "footer-module--title--2261d";