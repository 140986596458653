const normalizeString = (string) =>
  string
    ? string
        .trim()
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    : '';

const stringToBoolean = (str) => {
  if (str === undefined) return undefined;
  if (typeof str === 'boolean') return str;
  if (typeof str === 'string') return str.toLowerCase() === 'false' ? false : true;
  return !!str;
};

const stringToInt = (str) => {
  if (str === undefined) return undefined;
  if (typeof str === 'number') return parseInt(str);
  return str;
};

module.exports = {
  normalizeString,
  stringToBoolean,
  stringToInt,
};
